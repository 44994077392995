export const twicePI: number = Math.PI * 2;

export function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

export function toDegrees(radians: number): number {
  return radians * (180 / Math.PI);
}

export function approximatelyEquals(a: number, b: number, tolerance = Number.EPSILON) {
  return Math.abs(a - b) < tolerance;
}

export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export function clamp01(value: number): number {
  if (value < 0) return 0;
  else if (value > 1) return 1;
  else return value;
}

export function isCloseToZero(value: number, tolerance = Number.EPSILON): boolean {
  return Math.abs(value) <= tolerance;
}

export function isFloat(num: number): boolean {
  return Number(num) === num && num % 1 !== 0;
}

export function* generateSegments(chunks: number[], segment: number): Generator<[number, number]> {
  let remainingSegment = segment;
  //let i = 0;
  // c is never reassigned, but remainingChunk is. Gives an eslinting error. Disabling for now.
  // eslint-disable-next-line prefer-const
  for (let [c, remainingChunk] of chunks.entries()) {
    while (remainingChunk > 0) {
      const segmentSize = Math.min(remainingChunk, remainingSegment);
      yield [c, segmentSize];
      //i++;
      remainingChunk -= segmentSize;
      remainingSegment -= segmentSize;
      if (remainingSegment === 0) {
        remainingSegment = segment;
      }
    }
  }
}

/**
 * Cubic interpolation based on https://github.com/osuushi/Smooth.js
 */
export function cubicInterpolation(array: Array<number>, t: number, tangentFactor = 1) {
  const k = Math.floor(t);
  const m = [getTangent(k, tangentFactor, array), getTangent(k + 1, tangentFactor, array)];
  const p = [clipInput(k, array), clipInput(k + 1, array)];
  t -= k;
  const t2 = t * t;
  const t3 = t * t2;
  return (2 * t3 - 3 * t2 + 1) * p[0] + (t3 - 2 * t2 + t) * m[0] + (-2 * t3 + 3 * t2) * p[1] + (t3 - t2) * m[1];
}

export function clipInput(k: number, arr: Array<number>): number {
  if (k < 0) k = 0;
  if (k > arr.length - 1) k = arr.length - 1;
  return arr[k];
}

export function getTangent(k: number, factor: number, array: Array<number>): number {
  return (factor * (clipInput(k + 1, array) - clipInput(k - 1, array))) / 2;
}

/**
 * Compute the number of ways of obtaining an ordered subset of k elements from a set of n elements.
 *
 * Permutations only takes integer arguments. The following condition must be enforced: k <= n.
 * @param n The number of objects in total
 * @param k The number of objects in the subset
 * @returns The number of permutations
 */
export function permutations(n: number, k: number): number {
  if (k > n) throw new Error('k cannot be greater than n');
  let result = 1;
  for (let i = 0; i < k; i++) {
    result *= n - i;
  }
  return result;
}
